<template>
  <div class="home">
    <div class="zhaoming">
      <div class="body">
        <div class="wai1">
          <div class="case">
            <div class="lunbo">
              <!-- <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
                data-interval="3000"
                autoplay
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div
                    class="carousel-item"
                    v-for="(item, index) in slideList"
                    :key="item.ID"
                    :class="currentIndex == index"
                    style="position: relative"
                  >
                    <div class="textBox">
                      <div class="h1">{{ item.shortTitle }}</div>
                      <div class="p">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="image-item">
                      <img
                        :src="item.thumbnail"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>

                  <div class="carousel-item" style="position: relative">
                    <div class="textBox">
                      <div class="h1">每一位客户享受专属服务</div>
                      <div class="p">
                        企业具备优质服务，客户享受畅通体验，定制化开发服务，全天售后运维保障
                      </div>
                    </div>
                    <div class="image-item">
                      <img
                        src="@/assets/img/banner2.png"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <div class="carousel-item" style="position: relative">
                    <div class="textBox">
                      <div class="h1">风险检测</div>
                      <div class="p">
                        智能检测，您的企业风险诊断专属。
                        助力企业风险合规，智能辅助业务决策和企业经营效率提升!
                      </div>
                    </div>
                    <div class="image-item">
                      <img
                        src="@/assets/img/banner3.png"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-target="#carouselExampleIndicators"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-target="#carouselExampleIndicators"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </button>
              </div> -->
              <el-carousel indicator-position="outside">
                <el-carousel-item
                  v-for="item in slideList"
                  :key="item"
                  @click="goDetali(item)"
                >
                  <div class="textBox">
                    <div class="h1">{{ item.shortTitle }}</div>
                    <div class="p">
                      {{ item.title }}
                    </div>
                  </div>
                  <div class="image-item">
                    <img
                      :src="item.thumbnail"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <!-- <div class="case">
          <div class="swiper-slide">
            <div class="cont w1146">
              <ul>
                <li><img src="@/assets/img/xy.jpg" alt=""></li>
                <li><img src="@/assets/img/gt.jpg" alt=""></li>
                <li><img src="@/assets/img/jat.jpg" alt=""></li>
                <li><img src="@/assets/img/xy.jpg" alt=""></li>
                <li><img src="@/assets/img/xy.jpg" alt=""></li>
                <li><img src="@/assets/img/xy.jpg" alt=""></li>
              </ul>
            </div>
          </div>
        </div> -->
        <div class="case">
          <div class="juzheng">
            <div class="txt">
              <h1>全场景数智化产品矩阵</h1>
              <p class="txtp">
                以客户为中心，构建“服务+营销”一体化智能解决方案
              </p>
            </div>
            <div class="index-product">
              <div
                class="item"
                v-if="fivegList.length > 0"
                @click="goDetali(fivegList[0])"
              >
                <img
                  class="imgbg"
                  src="@/assets/img/product01.png"
                  alt="云呼叫中心"
                />
                <div class="txt1">
                  <h2>
                    {{ fivegList[0].shortTitle
                    }}<img src="@/assets/img/icon-flag.svg" alt="推荐" />
                  </h2>
                  <p class="black">
                    {{ fivegList[0].title }}
                  </p>
                  <previewblobformCheck :parent-id="fivegList[0].ID" />
                </div>
              </div>
              <div
                class="item2"
                v-for="item in twoList"
                :key="item.ID"
                @click="goDetali(item)"
              >
                <!-- <img class="imgbg" src="@/assets/img/product02.png" alt="在线"> -->
                <div class="txt1">
                  <h2>
                    {{ item.shortTitle
                    }}<img src="@/assets/img/icon-flag.svg" alt="推荐" />
                  </h2>
                  <p class="black">
                    {{ item.title }}
                  </p>
                  <previewblobformCheck :parent-id="item.ID" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="case">
          <div class="hangye">
            <div class="txt">
              <h1>满足各行业需求的整体解决方案</h1>
              <p class="txtp">助力企业一站式数字化升级，稳健增长</p>
            </div>
            <div class="index-case">
              <div
                class="item"
                v-for="item in pictureList"
                :key="item.ID"
                @click="goDetali(item)"
              >
                <img :src="item.thumbnail" alt="政务" />
                <div class="txt">
                  <h2>{{ item.shortTitle }}</h2>
                  <p>
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="wai">
          <div class="case">
            <div class="kehu">
              <div class="cont w1146">
                <div class="txt">
                  <h1>我们的客户</h1>
                  <p class="txtp">权威认证，深受多家知名企业认可</p>
                </div>
                <div class="swiper">
                  <div
                    id="carouselExampleControls"
                    class="carousel slide"
                    data-ride="carousel"
                  >
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div class="imger">
                          <div class="img">
                            <img src="@/assets/img/64197b0d7adbd.png" alt="" />
                          </div>
                          <div class="txt">
                            我们能够帮助各型企事业单位在企业内部管理、内部沟通、客户服务、业务应用、宣传推广等方面利用手机短信的应用达到节约办公成本，提高办公效率，创造企业效益的目的。
                          </div>

                          <div class="tag">
                            <span>房产家居</span>
                          </div>
                          <div class="logo-list">
                            <span>
                              <img
                                src="@/assets/img/645b43a6bfe25.jpg"
                                alt="龙湖"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b43b1d735b.jpg"
                                alt="百安居"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b443e80c29.jpg"
                                alt="欧派"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b44b00c49f.jpg"
                                alt="长鹿"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b44d06af62.jpg"
                                alt="中交"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="imger">
                          <div class="img">
                            <img
                              src="@/assets/img/645897dd2511b.jpg"
                              alt="得助智能"
                            />
                          </div>
                          <div class="txt">
                            得助智能为我司加快完成数字化、智能化升级提出了建设性意见，助力我司提升内部信息流转效率，确保在生产过程中各环节紧密衔接和高效运转，保障各部门平稳生产。
                          </div>

                          <div class="tag">
                            <span>智能制造</span>
                          </div>
                          <div class="logo-list">
                            <span>
                              <img
                                src="@/assets/img/645b422680ac1.jpg"
                                alt="海信"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b4235dc82e.jpg"
                                alt="菲尔莱"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b4242f0a9f.jpg"
                                alt="艾比森"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b425762e59.jpg"
                                alt="捷安特"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b42755517e.jpg"
                                alt="欧派"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="imger">
                          <div class="img">
                            <img src="@/assets/img/64197b0d7adbd.png" alt="" />
                          </div>
                          <div class="txt">
                            我们采用得助智能批量外呼功能，操作简单，提升人效，同时线路、系统性能非常稳定，对我们的业务开展提供了效果较为显著的帮助。
                          </div>

                          <div class="tag">
                            <span>房产家居</span>
                          </div>
                          <div class="logo-list">
                            <span>
                              <img
                                src="@/assets/img/645b43a6bfe25.jpg"
                                alt="龙湖"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b43b1d735b.jpg"
                                alt="百安居"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b443e80c29.jpg"
                                alt="欧派"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b44b00c49f.jpg"
                                alt="长鹿"
                              />
                            </span>
                            <span>
                              <img
                                src="@/assets/img/645b44d06af62.jpg"
                                alt="中交"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      data-target="#carouselExampleControls"
                      data-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      data-target="#carouselExampleControls"
                      data-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="case">
          <div class="hexin">
            <div class="indexMinker indexMinkerPad04">
              <div class="cont w1146">
                <div class="txt">
                  <h1>核心能力助推产业数字变革</h1>
                </div>
                <div class="index-ability">
                  <div class="item">
                    <div class="img">
                      <img src="@/assets/img/ability-01.png" alt="吉兔软件" />
                    </div>
                    <div class="txt">
                      <h2>产品体系更完整</h2>
                      <p>涵盖营销、运营、服务全价值，一站式服务</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="img">
                      <img src="@/assets/img/ability-02.png" alt="吉兔软件" />
                    </div>
                    <div class="txt">
                      <h2>行业洞察更深刻</h2>
                      <p>在行业认真耕耘，洞察客户需求细致入微，以效果为导向</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="img">
                      <img src="@/assets/img/ability-03.png" alt="吉兔软件" />
                    </div>
                    <div class="txt">
                      <h2>服务品质更专业</h2>
                      <p>细致服务客户，与客户携手共赢</p>
                    </div>
                  </div>
                  <!-- <div class="item">
                    <div class="img">
                      <img src="@/assets/img/ability-04.png" alt="吉兔软件">
                    </div>
                    <div class="txt">
                      <h2>资本实力更雄厚</h2>
                      <p>知名VC机构35亿投资，多年盈利</p>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="wai">
          <div class="case">
            <div class="qiye">
              <div class="indexMinker indexMinkerOne indexMinkerPad03">
                <div class="cont w1146">
                  <div class="txt">
                    <h1>更多先进企业选择吉兔软件</h1>
                  </div>
                  <div class="open index-open clearfix ">
                    <a href="javascript:;">
                      <img src="@/assets/img/643620c8d3d2a.png" alt="喜马拉雅 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620d45e2c7.png" alt="物美 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620c8d3d2a.png" alt="喜马拉雅 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620d45e2c7.png" alt="物美 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620c8d3d2a.png" alt="喜马拉雅 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620d45e2c7.png" alt="物美 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620c8d3d2a.png" alt="喜马拉雅 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620d45e2c7.png" alt="物美 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620c8d3d2a.png" alt="喜马拉雅 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620d45e2c7.png" alt="物美 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620c8d3d2a.png" alt="喜马拉雅 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620d45e2c7.png" alt="物美 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620c8d3d2a.png" alt="喜马拉雅 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                    <a href="javascript:;">
                      <img src="@/assets/img/643620d45e2c7.png" alt="物美 - 得助智能">

                      <span>敬请期待</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="case">
          <h1>企业动态</h1>
          <div class="flex_el">
            <div class="left">
              <img src="@/assets/img/63d87291214dd.jpg" alt="" />
              <h3>CPHI & PMEC China 全国主题巡展华南站盛大开幕</h3>
              <p class="date">2023-09-05</p>
              <p class="content">
                CPHI & PMEC China
                全国主题巡展华南站（简称“CPHI广州展”）于9月4日盛大开幕！
                250+医药知名企业齐齐亮相广州，深化协同优势，助力大湾区医药产业高质量发展，为产业注入新活力！
                贵客带雨，雨天来财。本次CPHI广州展开幕前恰逢最强台风之一“苏拉”登陆珠三角地区
              </p>
              <p class="more">查看更多 »</p>
            </div>
            <div class="right">
              <ul>
                <li>
                  <p class="title">2023HOTELEX成都展圆满收官！</p>
                  <p class="time">2023-08-21</p>
                </li>
                <li>
                  <p class="title">
                    博华展览-2024 FOOD PLUS广州联展五展齐发，诚邀共襄盛会！
                  </p>
                  <p class="time">2023-08-21</p>
                </li>
                <li>
                  <p class="title">
                    上海出台一系列行动方案和措施推动会展经济高质量发展！
                  </p>
                  <p class="time">2023-08-21</p>
                </li>
                <li>
                  <p class="title">
                    聚势共赢，同心致远，回顾CPHI & PMEC China 2023盛况！
                  </p>
                  <p class="time">2023-08-21</p>
                </li>
                <li>
                  <p class="title">HOTELEX国际咖啡美食文化节7月来北京啦！</p>
                  <p class="time">2023-08-21</p>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
      <!-- 侧边联系 -->
      <!-- <div class="dropleft d-ss-none">
        <div class="btn-wrap">
          <div class="icon-wrap phone">
            <img src="@/assets/img/phone.png" class="icon salesBtn2">
            <div class="phone-num">
              <div class="person">李总： 13326966576 </div>
              <div class="trangle"></div>
            </div>
          </div>
          <div class="icon-wrap wechat">
            <img src="@/assets/img/wechat.png" class="icon weBtn">
            <img src="@/assets/img/pic_home_sao.jpeg"
              class="we-code hideMenu showMenu">
          </div>
        </div>
      </div> -->
      <footer class="footer"></footer>
    </div>
  </div>
</template>

<script setup>
import { cmsArchivesList } from "@/api/contPublic";
import { ref } from "vue";
import previewblobformCheck from "./previewblobformCheck.vue";
import { useRouter } from "vue-router";
// const currentIndex = ref(0);
const router = useRouter();
const archivesList = ref([]);
const fivegList = ref([]);
const twoList = ref([]);
const pictureList = ref([]);
const slideList = ref([]);
const archivesFun = async () => {
  fivegList.value = [];
  twoList.value = [];
  slideList.value = [];
  pictureList.value = [];
  const res = await cmsArchivesList({ page: 1, pageSize: 100 });
  archivesList.value = res.data.data.list;
  // console.log(archivesList.value);
  archivesList.value &&
    archivesList.value.forEach((item) => {
      switch (item.flag) {
        case "a":
          fivegList.value.push(item);
          break;
        case "c":
          twoList.value.push(item);
          break;
        case "h":
          pictureList.value.unshift(item);
          break;
        case "f":
          slideList.value.unshift(item);
          break;
        default:
          break;
      }
    });

  // console.log(fivegList.value);
  // console.log(twoList.value);
};
archivesFun();

const goDetali = (item) => {
  // console.log(item);
  router.push({
    name: "newsDataViewAfter",
    params: { id: item.typeId, title: item.arcTypeName, ids: item.ID },
  });
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

.zhaoming {
  // width: 100%;
  min-width: 1200px;

  .wai1 {
    width: 100%;
    background-color: #f6f6fe;
    max-height: 480px;
    height: 480px;

    .case {
      width: 100%;
      max-height: 480px;
      height: 480px;

      .lunbo {
        background-color: #f6f6fe;
        width: 90%;
        margin: 0 auto;
        height: auto;
        position: relative;
        z-index: 0;
        transition: all 1s;
        top: 46px;

        &:hover {
          scale: 0.95;
        }

        .carousel {
          width: 100%;

          & .carousel-indicators li {
            background-color: #ccc;
          }
        }

        .carousel-inner {
          position: relative;
          width: 100%;
          overflow: hidden;
        }

        // .carousel-item {
        //   display: flex;
        // }

        .image-item {
          position: relative;
          width: 50%;
          overflow: hidden;
        }

        .textBox {
          height: 100%;
          width: 50%;
          color: #666;
          margin: 24px 0 32px 0;
          float: left;

          .h1 {
            padding: 10px;
            font-size: 40px;
            font-weight: bold;
            line-height: 48px;
            color: transparent;
            background: linear-gradient(
              -4deg,
              transparent,
              transparent 25%,
              #455a64,
              #2e3a40,
              transparent 75%,
              transparent
            );
            background-clip: text;
            background-size: 100% 400%;
            background-position: center 0;
            animation: textScroll 6s infinite linear alternate;
          }

          @keyframes textScroll {
            100% {
              background-position: center 100%;
            }
          }

          .p {
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            padding: 15px;
          }
        }

        /* 修改轮播图左右按钮为灰色透明 */
        .carousel-control-prev,
        .carousel-control-next {
          border-radius: 50%;
          border-color: transparent;
          background-color: rgba(128, 128, 128, 0.5);
          width: 40px;
          height: 40px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 1;
        }
      }
    }
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    margin-top: 35px;

    .cont {
      ul {
        display: flex;
        justify-content: flex-start;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        li {
          width: 200px;
          padding: 10px;
          white-space: nowrap;
          transition: transform 0.3s, box-shadow 0.3s;

          &:hover {
            cursor: pointer;
            transform: scale(0.85);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }

  .juzheng {
    margin-top: 35px;
    width: 100%;
    cursor: pointer;
    .txtp {
      margin-top: 35px;
      font-size: 18px;
      color: #666;
      text-align: center;
      line-height: 26px;
    }

    em {
      font-style: normal;
    }

    .index-product {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .item {
        width: 100%;
        height: 375px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;
        background-color: #f6f7fc;
        font-size: 16px;
        position: relative;
        overflow: hidden;

        .imgbg {
          width: auto;
          height: 375px;
          position: absolute;
          right: 0;
          top: 0;
          transition: all 0.6s;

          &:hover {
            transform: scale(1.05);
          }
        }

        .txt1 {
          position: relative;
          z-index: 1;
          text-align: left;

          & > .black {
            color: #51565d;
            // width: 380px;
            margin: 15px 0;
            font-size: 17px;
            font-weight: 700;
          }

          h2 > img {
            height: 20px;
            margin-left: 10px;
            color: #1f2329;
          }

          // .spanitem {
          //   width: 400px;

          //   &::before {
          //     content: "";
          //     display: inline-block;
          //     width: 4px;
          //     height: 4px;
          //     margin-top: 10px;
          //     margin-right: 10px;
          //     background-color: #1f2329;
          //     border-radius: 100%;
          //   }
          // }
        }

        .gear {
          display: flex;
          align-items: center;
          position: relative;
          z-index: 1;
          color: #51565d;
        }
      }

      .item2 {
        margin-top: 20px;
        width: calc(50% - 15px);
        height: 490px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;
        background-color: #f6f7fc;
        font-size: 16px;
        position: relative;
        overflow: hidden;

        .imgbg {
          width: auto;
          height: 375px;
          position: absolute;
          right: 0;
          top: 0;
          transition: all 0.6s;

          &:hover {
            transform: scale(1.05);
          }
        }

        .txt1 {
          position: relative;
          z-index: 1;
          text-align: left;

          & > .black {
            color: #51565d;
            // width: 380px;
            margin: 15px 0;
            font-size: 17px;
            font-weight: 700;
          }

          h2 > img {
            height: 20px;
            margin-left: 10px;
            color: #1f2329;
          }

          // span {
          //   width: 400px;

          //   &::before {
          //     content: "";
          //     display: inline-block;
          //     width: 4px;
          //     height: 4px;
          //     margin-top: 10px;
          //     margin-right: 10px;
          //     background-color: #1f2329;
          //     border-radius: 100%;
          //   }
          // }
        }

        .gear {
          display: flex;
          align-items: center;
          position: relative;
          z-index: 1;
          color: #51565d;
        }
      }
    }
  }

  .hangye {
    margin-top: 35px;
    width: 100%;
    cursor: pointer;
    .txtp {
      margin-top: 35px;
      font-size: 18px;
      color: #666;
      text-align: center;
      line-height: 26px;
    }

    em {
      font-style: normal;
    }

    .index-case {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        height: 240px;
        margin-top: 30px;
        overflow: hidden;
        position: relative;

        &:hover .txt {
          transform: translateY(-90%);

          & > p {
            opacity: 1;
          }
        }

        &:hover img {
          opacity: 0.9;
          filter: blur(5px);
        }

        & > img {
          width: 100%;
          min-height: 100%;
          transition: all 0.6s;
        }

        & > .txt {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 102px;
          padding: 36px;
          text-align: left;
          color: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
          transition: all 0.6s;

          & > h2 {
            font-size: 24px;
            line-height: 30px;
          }

          & > p {
            font-size: 17px;
            opacity: 0;
          }
        }
      }

      & .item:nth-child(-n + 2) {
        width: calc(50% - 15px);
      }

      & .item:nth-child(n + 3) {
        width: calc(100% / 3 - 20px);
        height: 240px;
        margin-top: 30px;
        overflow: hidden;
        position: relative;
      }
    }
  }

  .wai {
    background-color: #fff;

    .kehu {
      margin-top: 35px;
      width: 100%;

      .txtp {
        margin-top: 35px;
        font-size: 18px;
        color: #666;
        text-align: center;
        line-height: 26px;
      }

      .swiper {
        /* 修改轮播图左右按钮为灰色透明 */
        .carousel-control-prev,
        .carousel-control-next {
          border-radius: 50%;
          background-color: rgba(128, 128, 128, 0.5);
          width: 40px;
          height: 40px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 1;
          margin: 15px;
          border-color: transparent;
        }

        .imger {
          border-radius: 0;
          border: 0;
          height: auto;
          padding: 50px 40px;
          background: #fff;
          text-align: center;

          .img {
            width: 198px;
            height: 66px;
            border: 1px solid rgba(31, 35, 41, 0.1);
            padding: 0;
            margin: 0 0 0 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .txt {
            margin: 30px 0;
            font-size: 18px;
            color: #51565d;
            text-align: left;
            max-width: 850px;
            margin-left: 10px;
          }

          .tag {
            display: flex;
            padding-bottom: 20px;
            margin-left: 10px;

            & > span {
              background-color: rgba(31, 35, 41, 0.1);
              line-height: 40px;
              padding: 0 28px;
              border-radius: 4px;
              font-size: 16px;
              color: #51565d;
              margin-right: 10px;
            }
          }

          .logo-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            span {
              margin-top: 20px;
              transition: all 0.6s;
            }
          }
        }
      }
    }
  }

  .hexin {
    margin-top: 35px;
    margin-bottom: 25px;
    width: 100%;

    .txtp {
      margin-top: 35px;
      font-size: 18px;
      color: #666;
      text-align: center;
      line-height: 26px;
    }

    .index-ability {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      & .item {
        width: calc(25% - 20px);
        padding: 40px 18px;
        box-shadow: 0px 0px 18px 0px rgba(65, 65, 65, 0.1);
        border-radius: 8px;
        transition: all 0.6s;

        &:hover {
          box-shadow: 1px 0px 18px 0px rgba(70, 69, 69, 0.2);
          transform: scale(1.025);
        }

        & .img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          margin: 0 auto;
          transition: all 0.6s;
        }

        & h2 {
          font-size: 18px;
          line-height: 1.2;
          color: #1f2329;
          text-align: center;
          margin-top: 30px;

          &::after {
            content: "";
            display: block;
            width: 24px;
            height: 2px;
            background-color: #4c7ee9;
            margin: 10px auto 20px;
          }
        }

        & p {
          font-size: 14px;
          color: #51565d;
        }
      }
    }
  }

  .wai {
    padding: 15px;
    margin-bottom: 15px;

    .qiye {
      margin-top: 35px;
      width: 100%;

      .txtp {
        margin-top: 35px;
        font-size: 18px;
        color: #666;
        text-align: center;
        line-height: 26px;
      }

      .open {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .indexMinker .index-open {
        padding-top: 10px;

        & a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 128px;
          height: 60px;
          border-radius: 8px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(216, 218, 226, 0.53);
          margin-right: calc(121px / 7);
          margin-top: 20px;
          overflow: hidden;
          position: relative;

          &:hover > span {
            opacity: 1;
          }

          & > span {
            color: #646a73;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            font-size: 14px;
            color: #4c7ee9;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            transition: all 0.6s;
            overflow: hidden;
            opacity: 0;
          }

          & img {
            width: 100%;
          }
        }
      }
    }
  }

  .case {
    max-width: 1140px;
    margin: 0 auto;

    h1 {
      font-size: 26px;
      text-align: center;
      color: #001345;
      font-weight: bold;
      position: relative;

      &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: -20px;
        width: 180px;
        height: 7px;
        background-color: #4c7ee9;
        left: 50%;
        margin-left: -90px;
      }

      &::before {
        display: block;
        content: "";
        position: absolute;
        bottom: -12px;
        width: 180px;
        height: 1px;
        background-color: #4c7ee9;
        left: 50%;
        margin-left: -90px;
      }
    }

    .case_ul {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      flex-wrap: wrap;
      flex: 1;

      .case_li {
        width: 260px;
        padding: 10px;

        img {
          width: 100%;
          transition: background 0.3s, border 0.3s, border-radius 0.3s,
            box-shadow 0.3s,
            transform var(--e-transform-transition-duration, 0.4s);
          height: 170px;

          &:hover {
            transform: translateY(-8px);
          }
        }

        .title {
          margin-top: 10px;
          font-size: 15px;
          line-height: 27px;
          color: #042464;
          font-weight: bold;
          text-align: center;
        }

        .time,
        .name {
          font-size: 14px;
          text-align: center;
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .flex_el {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    flex: 1;
    flex-wrap: wrap;

    .left {
      width: 480px;
      padding: 10px;

      img {
        width: 100%;
        height: 280px;
      }

      h3 {
        margin-top: 20px;
        color: #132e67;
        font-size: 22px;
      }

      .date {
        color: #adadad;
        font-size: 12px;
      }

      .content {
        color: #777;
        font-size: 13px;
      }

      .more {
        color: #5b5b5b;
        font-size: 15px;
        cursor: pointer;
      }
    }

    .right {
      width: 560px;

      ul {
        padding: 50px 10px;

        li {
          width: 100%;
          border-bottom: 1px solid #eee;

          p {
            margin: 0;
          }

          .title {
            font-size: 15px;
            margin-top: 20px;
          }

          .time {
            color: #adadad;
            font-size: 12px;
            padding: 10px 0 0 0;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  ul,
  li {
    list-style-type: none;
  }
}

.dropleft {
  position: fixed;
  top: 50%;
  right: 15px;
  z-index: 10;

  .btn-wrap {
    display: block;
    position: fixed;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    right: 2px;
    z-index: 99999;
  }

  .icon-wrap {
    position: relative;
    margin-bottom: 10px;
    padding-top: 0px;
    margin-right: 0;
    margin-top: 0;

    .contactBtn {
      cursor: pointer;
    }

    .icon {
      width: 70px;
      height: 70px;
      border-radius: 10px;
    }

    .salesBtn2 {
      margin-left: 0 !important;
      right: 30px;
      /* border-radius: 50%; */
      background: #fff !important;
      box-shadow: 0px 2px 8px 0px rgba(0, 26, 50, 0.19);
      font-size: 12px;
    }

    img {
      object-fit: cover;
    }
  }

  .contact {
    display: flex;
    flex-wrap: wrap;
    margin-right: 100px;
  }

  .phone {
    &:hover .phone-num {
      display: block;
    }
  }

  .phone-num {
    position: absolute;
    right: 90px;
    top: 0;
    width: 220px;
    height: 80px;
    // padding-left: 22px;
    padding-top: 30px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: none;

    .person {
      margin-bottom: 10px;
      margin-left: 20px;
    }

    .trangle {
      position: absolute;
      right: -55px;
      bottom: 20px;
      height: 0;
      border-width: 15px 40px;
      transform: rotate(20deg);
      border-style: solid;
      border-color: transparent transparent transparent #fff;
    }
  }

  .wechat {
    .we-code {
      position: absolute;
      right: 75px;
      top: 0;
      width: 140px;
      height: 140px;
    }

    .hideMenu {
      display: none;
    }

    // .showMenu {
    //   display: block;
    // }
    &:hover .hideMenu {
      display: block;
    }
  }
}

@media screen and (max-width: 750px) {
  .wai1 {
    width: 100% !important;
  }

  .wai1 .case {
    min-height: 480px !important;
  }

  .lunbo {
    width: 100% !important;
    // .el-carousel{
    //   position: relative;
    // }
    // .el-carousel__indicators {
    //   position: absolute;
    //   bottom:10px
    // }
    &:hover {
      scale: 1 !important;
    }
    .el-carousel__arrow{
      display: block !important;
    }
    .el-carousel__item {
      height: 370px;
      display: flex;
      flex-direction: column-reverse;
      .textBox {
        margin: 0 !important;
        text-align: center;
        width: auto !important;
      }
    }

    .image-item {
      position: relative;
      width: 100% !important;
      // bottom: 240px;
    }
  }

  // juzheng
  .index-product {
    padding: 0 10px;

    .item {
      width: 100% !important;
      height: auto;
      padding: 15px !important;
      margin-top: 17px !important;

      .txt1 {
        // padding: 15px 20px 0;
        // background-color: rgba(255, 255, 255, .7);
        border-radius: 4px 4px 0 0;

        p {
          max-width: 380px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
        }

        h2 {
          justify-content: space-between;
          font-size: 16px;
        }

        span::before {
          margin-top: 8px;
        }
      }

      .item .imgbg {
        min-height: 100%;
      }

      .gear {
        padding: 0 20px 15px;
        // background-color: rgba(255, 255, 255, .7);
        border-radius: 0 0 4px 4px;
        padding-top: 25px;
      }
    }

    .item2 {
      height: 515px !important;
      min-width: 100%;
      padding: 15px !important;

      .txt1 {
        // padding: 15px 20px 0;
        // background-color: rgba(255, 255, 255, .7);
        border-radius: 4px 4px 0 0;

        p {
          max-width: 380px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
        }

        h2 {
          justify-content: space-between;
          font-size: 16px;
        }

        span::before {
          margin-top: 8px;
        }
      }

      .item .imgbg {
        min-height: 100%;
      }

      .gear {
        padding: 0 20px 15px;
        // background-color: rgba(255, 255, 255, .7);
        border-radius: 0 0 4px 4px;
        padding-top: 25px;
      }
    }
  }

  // hangye
  .indexMinkerPad02 {
    padding: 25px 0;
  }

  .w1146 {
    overflow: hidden;
  }

  .index-tit {
    width: 300px;
    margin: 30px auto 0;

    .h1 {
      font-size: 24px;
      color: #303133;
    }

    .mk {
      font-size: 14px;
      line-height: 24px;
      color: #666666;
      margin-top: 6px;
    }
  }

  .index-case {
    padding: 0 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 100% !important;
      height: 70px;
      margin-top: 12px;
      overflow: hidden;
      position: relative;

      & > .txt {
        padding: 20px 32px;
        height: 70px;

        transition: all 0s;
      }

      & > img {
        min-height: 100%;
      }
    }
  }

  // kehu
  .kehu {
    .imger .logo-list {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  // hexin
  .index-ability {
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 0;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
      box-shadow: none;
      border-bottom: 1px solid #ebeef5;
      border-radius: 0;
      padding: 32px 0;
      margin-bottom: 10px;

      .img {
        width: 64px;
        height: 64px;
        background-color: #f3f7fd;
        border: 1px solid #ebeef5;
        margin: 0;

        .img > img {
          max-height: 40px;
        }
      }

      .txt {
        width: calc(100% - 88px);
      }

      h2 {
        margin-top: 0;
        font-size: 16px;
        text-align: left;
      }

      p {
        font-size: 12px;
        margin-top: 10px;
        text-align: left;
      }
    }
  }

  .indexMinker {
    .index-open {
      padding: 0 15px;

      a {
        margin-right: 12px !important;
        margin-left: 0 !important;
        border-radius: 0;
        border: 1px solid #ebeef5;
        box-shadow: none !important;
      }
    }
  }
}
</style>