<template>
  <div >
    <div id="print" style="min-height: 356px" v-html="editorContent"></div>
  </div>
</template>
<script>
export default {
  name: "PreviewBlobForm",
};
</script>
<script setup>
import { ref, defineProps } from "vue";
import {
  cmsArchivesDetail,
} from "@/api/contPublic";
// addFeedBack,
import { useRoute } from "vue-router";

const route = useRoute();
// const router = useRouter();
const editorContent = ref();
const props = defineProps({
  parentId: {
    type: String,
  },
});


const time = ref("");
const title = ref("");
const id=ref(0)
const flag=ref(false)
const init = async () => {
  // console.log("00000",props.parentId);
  if (route.params.ids) {
    id.value=route.params.ids
    const res = await cmsArchivesDetail({ ID: route.params.ids });
    editorContent.value = res.data.data.addonarticle.body;
    title.value = res.data.data.archives.title;
    time.value = res.data.data.archives.pubDate;
    flag.value=res.data.data.archives.notPost
  }
  if (props.parentId) {
    id.value=props.parentId
    const res = await cmsArchivesDetail({ ID: props.parentId });
    editorContent.value = res.data.data.addonarticle.body;
    title.value = res.data.data.archives.title;
    time.value = res.data.data.archives.pubDate;
  }
};
init();


</script>
<style lang="scss" scoped>
.comment {
  padding: 20px;
  & > .input{
    display: flex;
    align-items: center;
    .inp {
    width: 80%;
    border: 1px solid #ccc;
    height: 40px;
    text-indent: 5px;
    border-radius: 5px;
    margin-right: 20px;
  }
  
  }
  .comment_t {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    li {
      display: flex;
      margin: 10px 0;
      & > img {
        width: 35px;
        height: 35px;
        // border-radius: 50%;
      }
      .right {
        display: flex;
        margin-left: 20px;
        flex-direction: column;
        align-items: start;
        justify-content: space-around;
        .right_top {
          .name {
            color: #eb7350;
            font-size: 14px;
          }
          .title {
            font-size: 14px;
          }
        }
        .right_bottom {
          span {
            font-size: 12px;
            color: #9d9d9d;
          }
        }
      }
    }
  }
}
// body {
//   margin: 0px;
//   padding: 0px;
//   font-size: 12px;
//   font-family: '宋体';
// }

// #print {
//     background: url(@/assets/img/News_top.png) no-repeat;
//     width: 800px;
//     // height: 50px;
// }
.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  padding-right: 20px;
  color: #333;
}

// #print {
//   max-width: 755px;
//   overflow-x: auto;
//   margin: 0 auto;
// }

.el-button {
  color: #FFF !important;
  border-color: #FFF !important;
  background-color: #71a7f6 !important;
  outline: 0;
}

.el-button:hover {
  color: #fff !important;
  border-color: #FFF !important;
  background-color: #71a7f6 !important;
  outline: 0;
}
img {
  max-width: 100%;
}
</style>