import 'element-plus/dist/index.css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/notification/style/css'
import 'element-plus/es/components/message-box/style/css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import zhCN from "element-plus/dist/locale/zh-cn.mjs";
import VueSlickCarousel from 'vue-slick-carousel';
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import App from './App.vue'
import router from './router'
import store from './store'
import VueFullPage from 'vue-fullpage.js'


createApp(App).use(store).use(router).use(zhCN).use(ElementPlus).use(VueFullPage).use(VueSlickCarousel).mount('#app')
router.afterEach(() => {
    window.scrollTo(0,0);
    // console.log(111);
    
});