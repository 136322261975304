// 导入axios
import axios from 'axios'
//  import qs from 'qs'

// 基地址
const service = axios.create({
  baseURL: '/api',
  // 5秒超时
  timeout: 10000
})
// const con = (params) =>{
//   return qs.stringify(params, { arrayFormat: 'repeat' })
// }
// 请求拦截
service.interceptors.request.use(
  config => {
    // if (config.method === 'get') {
    //   // console.log(111,config);
    //   // console.log(config.paramsSerializer,222);
    //   console.log(config.params);
    //   config.paramsSerializer=qs.stringify(config.params, { arrayFormat: 'repeat' })
    //   console.log(config.paramsSerializer,333);
    // }
    config.headers = {
        'Content-Type': 'application/json',
        // 'x-token': userStore.token,
        // 'x-user-id': userStore.userInfo.ID,
        ...config.headers
      }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
 
// 响应拦截
service.interceptors.response.use(
  res => {
    return res
  },
  // 响应错误的代码写这里
  error => {
    return Promise.reject(error)
  }
)
 
// 暴露副本
export default service