import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { keepAlive: true }
  },
  {
    path: '/previewblobform/:ids',
    name: 'previewblobform',
    component: () => import('@/views/previewblobform.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/directional/:id/:title',
    name: 'directional',
    component: () => import('@/views/directionalView.vue')
  },
  
  {
    path: '/news/:id/:title',
    name: 'news',
    component: () => import('@/views/newsView.vue')
  },
  {
    path: '/newsData/:id/:title/:ids',
    name: 'newsData',
    component: () => import('@/views/newsDataView.vue')
  },
  {
    path: '/newsDataViewAfter/:id/:title/:ids',
    name: 'newsDataViewAfter',
    component: () => import('@/views/newsDataViewAfter.vue')
  },
  
  // 新闻封面
  {
    path: '/newsCover/:id/:title',
    name: 'newsCover',
    component: () => import('@/views/newsCoverView.vue')
  },
  
  {
    path: '/previewblobformCheck/:id',
    name: 'previewblobformCheck',
    component: () => import('@/views/previewblobformCheck.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/gateway',
    name: 'gateway',
    component: () => import('@/views/gatewayView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/productView.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('@/views/messageView.vue')
  },
  {
    path: '/discuss',
    name: 'discuss',
    component: () => import('@/views/discussView.vue')
  },
  {
    path: '/detection',
    name: 'detection',
    component: () => import('@/views/detectionView.vue')
  },
  {
    path: '/productCenter/:id/:title',
    name: 'productCenter',
    component: () => import('@/views/productCenterView.vue')
  },
  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (from.path !== to.path) {
    // 如果不是同一个页面，则跳转后自动滚动到顶部
    next(() => {
      window.scrollTo(0, 0);
    });
  } else {
    next();
  }
})
export default router
