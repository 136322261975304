import service from './request'

// 栏目下文章
export const getContArchivesByArcType = (params) => {
  return service({
    url: '/contPublic/getContArchivesByArcType',
    method: 'get',
    params
  })
}

// 查询栏目下的文章


// 栏目
export const getContArctypeList = (params) => {
  return service({
    url: '/contPublic/getContArctypeList',
    method: 'get',
    params
  })
}

// contPublic/getContArctypeTreeListByid
// [get]
// 栏目查询
export const getContArctypeTreeListByid = (params) => {
  return service({
    url: '/contPublic/getContArctypeTreeListByid',
    method: 'get',
    params
  })
}

// 树形栏目
export const getContArctypeTreeList = (params) => {
  return service({
    url: '/contPublic/getContArctypeTreeList',
    method: 'get',
    params
  })
}
// 文章内容
export const findcontArchives = (params) => {
  return service({
    url: '/contPublic/findContArchives',
    method: 'get',
    params
  })
}
// contPublic/getArchivesNoRank
// 所有文章内容 后台使用
export const getArchivesNoRank = (params) => {
  return service({
    url: '/contPublic/getArchivesNoRank',
    method: 'get',
    params
  })
}

// 查询栏目下的文章
// 头条文章
export const findHeadlineArchive = (params) => {
  return service({
    url: '/contPublic/findHeadlineArchive',
    method: 'get',
    params
  })
}


// 特别推荐+头条
export const getFirstHeadline = (params) => {
  return service({
    url: '/contPublic/getFirstHeadline',
    method: 'get',
    params
  })
}


// 根据varname获取对应sysconfig数据 varname：[“cfg_webname”]
export const getSysConfigByVarName = (params) => {
  return service({
    url: '/contPublic/getSysConfigByVarName',
    method: 'get',
    params
  })
}


// contPublic/getFlink
// 【get】前台分页获取友链
export const getFlink = (params) => {
  return service({
    url: '/contPublic/getFlink',
    method: 'get',
    params
  })
}


// 创建评论
//contPublic/addFeedBack[post]
export const addFeedBack = (data) => {
  return service({
    url: '/cmsFeedback/comment',
    method: 'post',
    data
  })
}

//contPublic/getFeedBackList[get]根据文章id获取对应的评论列表
export const getFeedBackList = (params) => {
  return service({
    url: '/cmsFeedback/list',
    method: 'get',
    params
  })
}













// cmsArchives/list 【get】获取公开文章列表
export const cmsArchivesList = (params) => {
  return service({
    url: '/cmsArchives/list',
    method: 'get',
    params
  })
}

// cmsArctype/treeList【get】栏目树形
export const treeList = (params) => {
  return service({
    url: '/cmsArctype/treeList',
    method: 'get',
    params
  })
}

// cmsSysconfig/config【get】 获取系统配置
export const cmsSysconfig = (params) => {
  return service({
    url: '/cmsSysconfig/config',
    method: 'get',
    params
  })
}

// cmsArctype/archive【get】分页获取栏目下的所有文章
export const archive = (params) => {
  return service({
    url: '/cmsArctype/archive',
    method: 'get',
    params
  })
}

// cmsArchives/detail【get】根据id查询文章详情
export const cmsArchivesDetail = (params) => {
  return service({
    url: '/cmsArchives/detail',
    method: 'get',
    params
  })
}


// cmsArctype/detail【get】 栏目内容
export const cmsArctypeDetail = (params) => {
  return service({
    url: '/cmsArctype/detail',
    method: 'get',
    params
  })
}

