import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    ruleForm:{}
  },
  getters: {
  },
  mutations: {
    updateRuleForm(state, ruleForm) {
      state.ruleForm = ruleForm; // 更新 ruleForm
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        // 存储方式
        storage: window.sessionStorage,
        key: 'store', // 指定要持久化的 state 属性
        reducer(state) {
          return { ...state } // 返回需要持久化的 state 属性
        }
      }
    )
  ]
})
