<template>
  <div class="body">
    <!-- <full-page ref="fullpage" :options="options"> -->
    <div class="nav">
      <div class="menu_nav contain">
        <div class="menu_box">
          <div class="img_box">
            <img src="@/assets/img/logo.png" alt="Logo" class="logo-img" />
          </div>

          <ul class="menu_list">
            <li
              v-for="menuItem in menuItems"
              :key="menuItem.id"
              class="menu_item"
              :class="{ has_submenu: menuItem.submenu }"
            >
              <p
                @click="toPush(menuItem.url, menuItem.id, 1, menuItem.label)"
                :style="iNum == menuItem.id ? 'color:#4c7ee9' : ''"
              >
                {{ menuItem.label }}
              </p>
              <div v-if="menuItem.submenu" class="sub_menu_box">
                <ul class="sub_menu_list">
                  <li
                    v-for="subMenuItem in menuItem.submenu"
                    :key="subMenuItem.id"
                    class="sub_menu_item"
                    @click="
                      toPush(
                        subMenuItem.url,
                        subMenuItem.id,
                        0,
                        subMenuItem.label,
                        menuItem.id
                      )
                    "
                  >
                    <p :style="iNums == subMenuItem.id ? 'color:#4c7ee9' : ''">
                      {{ subMenuItem.label }}
                    </p>
                    <!-- <router-link :to="subMenuItem.url">{{ subMenuItem.label }}</router-link> -->
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- {{$route.meta.keepAlive}} -->

    <el-config-provider :locale="zhCN">
      <!-- <KeepAlive>
             <router-view  :searchKeyword="searchKeyword"/>
           </KeepAlive> -->
      <!-- <router-view v-slot="{ Component }" :searchKeyword="searchKeyword">
            <keep-alive>
              <component :is="Component" /> 
            </keep-alive>
          </router-view> -->
      <!-- <router-view v-slot="{ Component }" :searchKeyword="searchKeyword" v-if="!$route.meta.keepAlive">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view> -->
      

      
      <router-view  :searchKeyword="searchKeyword" />
    </el-config-provider>
    <!-- <div class="foot">
      <div class="contain">
        版权所有&nbsp;
          中山市慈善总会 &nbsp;copyright&nbsp; ©&nbsp; 2009-2015 
      </div>
    </div> -->
  <!-- </full-page> -->
  </div>
</template>
<!-- <script>
      // 检测移动端
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      // 去掉某些script文件的引入
      if (isMobile) {
          const scripts = document.querySelectorAll('script');
          scripts.forEach(script => {
              if (script.src.includes('/canyou/js/wza.min.js')) {
                  script.remove();
              }else if(script.src.includes('/canyou/js/wzatool-mp.js')){
                script.remove();
              }
          });
      }
  </script> -->
<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import zhCN from "element-plus/dist/locale/zh-cn.mjs";
// import keep-alive from 'vue-keep-alive'
import { treeList } from "@/api/contPublic";

// import config from "@/core/config";
import { cmsSysconfig } from "@/api/contPublic";
// const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
import { watch } from "vue";
const route = useRoute();
watch(
  () => route.params.ids,
  () => {
    // window.sessionStorage.setItem("iNum", route.params.id);
    // console.log(route.path.includes('newsDataViewAfter'));
    if (route.path.includes("newsDataViewAfter")) {
      window.sessionStorage.setItem("iNum", 0);
      window.sessionStorage.setItem("iNums", 0);
      inita();
      // console.log(iNum.value);
    }
  }
);

// const options = ref({
//   licenseKey: "YOUR_KEY_HERE",
//   scrollOverflow: true,
//     scrolloverflowmacstyle: false,
// });
const router = useRouter();
const name = ref("");
// const keyName=ref('')
const iNum = ref(0);
const iNums = ref(0);
// const footName = ref({});
// const flinkDate = ref([]);
const inita = async () => {
  iNum.value = window.sessionStorage.getItem("iNum") || 0;
  iNums.value = window.sessionStorage.getItem("iNums") || 0;
  // console.log(router);

  // const res = await getSysConfigByVarName({ varname: ["cfg_webname",'cfg_postal','cfg_address','cfg_phone','cfg_copyright','cfg_security','cfg_record'] });
  const res = await cmsSysconfig({ varname: "cfg_webname" });
  // console.log(res);

  name.value = res.data.data.value;
  document.title = name.value;

  // const Flink = await getFlink({ page: 1, pageSize: 100 });
  // // console.log(Flink);
  // if (Flink.data.code == 0) {
  //   flinkDate.value = Flink.data.data.list;
  // }
};
inita();
// document.title=config.appName

const searchKeyword = ref("");

const init = async () => {
  const res = await treeList({
    page: 1,
    pageSize: 999,
    ishidden: 0,
  });
  if (res.data.code == 0) {
    const list = res.data.data;
    // console.log(list);
    menuItems.value = [{ id: 999, label: "首页", url: "/" }];
    list.forEach((item) => {
      if (!item.children) {
        menuItems.value.push({
          id: item.ID,
          label: item.typeName,
          url: item.channelType,
        });
      } else {
        const submenu = [];
        item.children.forEach((items) => {
          submenu.push({
            id: items.ID,
            label: items.typeName,
            url: items.channelType,
          });
        });
        menuItems.value.push({
          id: item.ID,
          label: item.typeName,
          url: item.channelType,
          submenu: submenu,
        });
      }
    });
    // console.log(menuItems.value);
  }
};
init();

// const submitSearch = () => {
//   // 处理搜索逻辑，你可以在这里添加相关的处理代码
//   // console.log("搜索关键字:", searchKeyword.value);
//   // 例如，使用路由进行页面跳转
//   searchKeyword.value=keyName.value
//   router.push({ name: "news", params: { id:63,title: '公益资讯' } });
// };

const menuItems = ref([]);

const toPush = (url, id, i, title, ids) => {
  // console.log(url, id, i, title);
  if (i == 1) {
    iNum.value = id;
    console.log(id);

    window.sessionStorage.setItem("iNum", iNum.value);
    // console.log( menuItems.value);
    menuItems.value.forEach((item) => {
      if (item.id == id) {
        // console.log(item);
        if (item.submenu) {
          router.push({
            name: item.submenu[0].url,
            params: { id: item.submenu[0].id, title: item.submenu[0].label },
          });
          iNums.value = item.submenu[0].id;
          window.sessionStorage.setItem("iNums", iNums.value);
        } else {
          window.sessionStorage.setItem("iNums", 0);

          if (url == "/") {
            router.push({ path: url });
          } else {
            router.push({ name: url, params: { id, title } });
          }
        }
      }
    });
    // window.location.reload()
  } else {
    router.push({ name: url, params: { id, title } });
    // window.location.reload()
    iNum.value = ids;
    iNums.value = id;
    console.log(iNum.value);

    window.sessionStorage.setItem("iNum", iNum.value);
    window.sessionStorage.setItem("iNums", iNums.value);
  }
  iNum.value = window.sessionStorage.getItem("iNum") || 0;
  iNums.value = window.sessionStorage.getItem("iNums") || 0;
};
</script>
<style lang="scss">
// html{
//   background: #f2f2f2;
// }

:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px #fff !important;
}
// .el-select-dropdown__item.selected {
//   color: #fff !important;
// }
:deep(.el-select-dropdown__item.hover, .el-select-dropdown__item:hover) {
  background-color: #fff;
}
:deep(.is-active) {
  background-color: #fff !important;
}
:deep(.el-select .el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px #fff inset !important;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
  box-shadow: 0 0 0 1px #fff !important;
}
:deep(.el-pager li:hover) {
  color: #fff !important;
}

:deep(.el-pager li.is-active:hover) {
  color: #fff !important;
}

:deep(.el-pagination button:hover) {
  color: #fff !important;
}
.body {
  margin-bottom: 200px;
}
.foot {
  width: 100%;
  min-width: 1200px;
  height: 200px;
  .youlian {
    display: flex;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-wrap: wrap;
    & > div {
      margin-bottom: 4px;
    }
    li {
      // border: 1px solid #fff;
      color: #fff;
      font-size: 14px;
      line-height: 26px;
      margin-right: 4px;
      margin-bottom: 4px;
      // border-radius: 2px;
      &:last-child > a::after {
        display: none;
      }
      & > a {
        padding: 0 10px;
        position: relative;
        color: #fff;
        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          height: 100%;
          background-color: #fff;
          width: 1px;
          top: 0;
        }
      }
    }
  }
  .foot_text,
  .foot_address,
  .foot_bootom {
    padding-top: 15px;
    font-size: 16px;
    color: #fff;
    line-height: 38px;
    text-align: center;
  }
  .foot_address {
    padding-top: 0;
  }
  .foot_bootom {
    text-align: center;
  }
}
@import "./assets/css/base.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@import "normalize.css";
.el-pagination {
  justify-content: center;
}
.body {
  margin: 0px;
  padding: 0;
}

.header {
  /* 设置背景颜色 */
  width: 100%;
  min-width: 1200px;
}

.contain {
  width: 1200px;
  // width: 80%;
  // max-width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
}

.header-inner {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo-box {
  margin-right: 20px;
}

.h-search-ctl {
  width: 200px;
  margin-right: 5px;
  border-radius: 50% !important;
}

.main-box {
  width: 410px;
  height: 42px;
  display: flex;
  align-items: center;
}

.user-link {
  margin-right: 10px;
  color: #fff;
  /* 设置链接颜色 */
  text-decoration: none;
  /* 去除下划线 */
  font-weight: bold;
  /* 加粗文字 */
  transition: color 0.3s ease;
  /* 添加颜色变化的过渡效果 */

  &:hover {
    color: #4c7ee9;
    /* 设置鼠标悬停时的颜色 */
  }
}

nav {
  padding: 0px;
}

.nav {
  // background: url('../assets/img/组\ 16@3x.png') no-repeat;
  // background-color: red;

  // height: 70px;
  border-bottom: 1px solid #f7f7f7;
  padding: 0px 0;
  margin: 0;
  background-size: cover;
  width: 100%;
  min-width: 1200px;
  .img_box {
    width: 300px;
    & > img {
      width: 90%;
    }
  }

  .menu_nav {
    .menu_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      // padding: 10px;
    }

    .menu_list {
      // display: flex;
      list-style: none;
      margin: 0;
      padding: 0 20px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .menu_item {
        display: inline-block;
        position: relative;
        width: 150px;
        height: 60px;
        line-height: 60px;
        font-weight: normal;
        font-size: 15px;
        text-align: center;
        &:hover p {
          color: #4c7ee9;
        }

        &:hover .sub_menu_box {
          display: block;
        }

        .sub_menu_box {
          display: none;
          width: 100%;
          left: 0px;
          top: 44px;
          position: absolute;
          z-index: 500;
          background: #ffffff;
          box-shadow: 0 3px 5px #bdbdbd;
        }

        .sub_menu_list {
          margin: 0px;
          padding: 0px;
        }

        .sub_menu_item {
          list-style-type: none;
          height: 45px;
          line-height: 45px;
          color: #505050;
          text-align: center;

          &:hover {
            background-color: #fff;

            p {
              color: #4c7ee9;
              cursor: pointer;
            }
          }

          p {
            color: #505050;
            text-decoration: none;
            font-weight: bold;
            transition: background-color 200ms ease-out;
          }
        }

        p {
          color: #505050;
          text-decoration: none;
          font-weight: bold;
          transition: color 0.3s ease;
          cursor: pointer;
        }
      }
    }
  }
}

.lunbo {
  position: relative;
  width: 100%;
  // min-width: 1200px;

  img {
    width: 100%;
    height: 100%;
  }

  // .banxi {
  //   position: relative;
  // }

  .zhezhao {
    // bottom: 0px;
    font-size: 24px;
    position: absolute;
    background-color: rgba(128, 128, 128, 0.5);
    height: 40px;
    text-align: center;
    line-height: 40px;
    width: 100%;
    bottom: 0px;

    p {
      min-width: 400px;
      line-height: 40px;
      height: 40px;
    }
  }
}

.numberofdonations {
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
  width: 1200px;
  // height: 270px;
  border: 5px solid rgba(253, 190, 83, 1);
  flex-direction: row;
  flex-wrap: nowrap;
  // justify-content: space-between;
  align-items: center;

  text-align: center;
  .content {
    // width: calc(100% - 420px);
    ul {
      justify-content: space-around;
      display: flex;
      margin-left: 15px;
      flex-direction: row;
      flex-wrap: wrap;
    }

    ul > li {
      // background: #000;
      // border: 1px solid;
      width: 350px;
      padding: 19px 5px;

      p {
        color: red;
        font-size: 25pt;

        span {
          color: rgba(253, 190, 83, 1);
        }
      }

      .text {
        padding-bottom: 30px;
        color: black;
        font-size: 24px;
      }
    }
  }

  .bici {
    width: 280px;
    // margin-right: 20px;
    margin: 20px 20px 20px 100px;
    .text {
      width: 100%;
      margin-bottom: 30px;
      font-size: 20px;
      height: 60px;
      background-color: rgba(230, 0, 45, 1);
      text-align: left;
      line-height: 60px;
      border-radius: 5px;
      padding: 0 20px;
      color: #fff;
      .icon-more {
        padding: 0 0 0 55px;
        color: #fff;
        // background: url('../assets/img/btn_next.png') no-repeat;
      }
    }
    .text:last-child {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.zixun {
  margin-top: 50px;

  .top-content {
    height: 115px;

    .logo {
      width: 80px;
      height: 115px;
    }

    .title {
      margin-left: 65px;
      margin-top: -110px;

      h3 {
        color: rgba(226, 1, 32, 1);
        font-size: 40px;
        padding: 0 24px 18px 64px;
        text-align: left;
      }

      .wenzi {
        font-size: 18px;
        padding-left: 65px;
        text-align: left;

        span {
          font-size: 18px;
          color: rgba(226, 1, 32, 1);
        }
      }
    }
  }

  .bottom-content {
    display: flex;
    margin-top: 45px;
    margin-bottom: 45px;

    .pic {
      height: 360px;
      width: calc(50%);

      img {
        height: 360px;
        width: 100%;
      }
    }

    .biaotineirong {
      ul {
        width: 464px;
        height: 270px;
      }

      li {
        width: 464px;
        list-style: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }

      width: calc(50%);
      padding: 0px 0px 40px 40px;
      line-height: 45px;
      display: flex;
      flex-direction: row;

      .anniu {
        width: 60px;
        color: #fff;
        height: 30px;
        background-color: rgba(230, 0, 45, 1);
        line-height: 30px;
        text-align: center;
        margin-top: 290px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .body{
    min-width: 375px !important;
  }
  
  .contain{
    min-width: 375px;
  }
  .zhaoming{
    min-width: 375px !important; 
  }
  .nav{
    min-width: 375px;
  }
  .menu_list{
    // overflow-x: scroll;
    white-space: nowrap;
    justify-content: flex-start !important;
    
    width: 100%;
    .menu_item{
      font-size: 10px !important;
      flex-shrink: 0;
      width: 50px !important;
    }

  }
//   ::-webkit-scrollbar {
//   /* 隐藏滚动条 */
//   display: none;
// }
}
</style>
